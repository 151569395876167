<template>
  <div class="background">
    <div class="titleimg">
      <div class="title">
        <div class="Icon"></div>

        <RouterLink to="Intro">
          <button class="next"></button>
        </RouterLink>

        <RouterLink to="/">
          <button class="home"></button>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'v-guide',
})
</script>


<style scoped>
.title{
  background-image: url('./../../public/img/Guide.png'); /* Replace 'your-background-image.jpg' with your image path */
}
</style>