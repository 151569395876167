import { createWebHashHistory, createRouter } from 'vue-router'

import HomeView from './components/Home.vue'
import IntroView from './components/Intro.vue'
import GuideView from './components/Guide.vue'
import DrawingView from './components/Drawing.vue'

const routes = [
    { path: '/', component: HomeView },
    { path: '/intro', component: IntroView },
    { path: '/guide', component: GuideView },
    { path: '/drawing', component: DrawingView },
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes,
})