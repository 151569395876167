<template>
  <main>
    <RouterView />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',

  data () {
    return {
      //
    }
  },
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.background {
  background-image: url('./../public/img/BG.jpg'); /* Replace 'your-background-image.jpg' with your image path */
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 100vh;
}
.titleimg{
  background-image: url('./../public/img/Title Image.png'); /* Replace 'your-background-image.jpg' with your image path */
  background-size: contain;
  background-position: top;
  background-repeat: repeat-x;
  width: 100%;
  height: 100vh;
}
.Icon{
  background-image: url("./../public/icons/Icon.png");
  background-color: transparent;
  border-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  width: 10%;
  height: 10%;
  max-width: 80px;
  max-height: 80px;
  position: absolute;
  top: 25px;
  right: 25px;
  border: none;
}
.title{
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.next{
  background-image: url("./../public/icons/Next Page Button.png");
  background-color: #00000000;
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.home{
  background-image: url("./../public/icons/Home Button.png");
  background-color: #00000000;
  background-size: contain;
  background-repeat: no-repeat;
  width: 10%;
  height: 10%;
  max-width: 50px;
  max-height: 50px;
  position: absolute;
  top: 25px;
  left: 25px;
  border: none;
}
</style>
