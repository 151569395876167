import { defineComponent } from 'vue'
import { DataFrame } from './DrawingInterface';
import CssFilterConverter from 'css-filter-converter';

export default defineComponent({
  name: 'v-drawing',
  beforeMount() {
    console.log("beforeMount");
    this.sections = document.querySelectorAll(".section");
    this.nextButton = document.querySelector(".next") || undefined;
    this.previousButton = document.querySelector(".previous") || undefined;
  },
  unmounted(){
    console.log("unmounted");
    window.removeEventListener("resize", () => this.setsize())
    if(this.canvas != undefined){
        this.canvas.removeEventListener('touchstart', this.touchstart, false);
        this.canvas.removeEventListener('touchmove', this.touchmove, false);
        this.canvas.removeEventListener('touchend', this.touchend, false);        
    
        this.canvas.removeEventListener('mousedown', this.drawstart, false);
        this.canvas.removeEventListener('mousemove', this.drawmove, false);
        this.canvas.removeEventListener('mouseup', this.drawend, false);
    }else{
        console.warn("[unmounted] canvas is undefined")
    }
    
  },
  mounted(){
    console.log("mounted");
    this.board = document.getElementById('drawing-board') as HTMLElement || undefined;
    this.canvas = document.getElementById('canvas') as HTMLCanvasElement || undefined;
    if(this.canvas != undefined){
        this.ctx = this.canvas.getContext("2d") || undefined;
        this.canvas.addEventListener('touchstart', this.touchstart, false);
        this.canvas.addEventListener('touchmove', this.touchmove, false);
        this.canvas.addEventListener('touchend', this.touchend, false);        

        this.canvas.addEventListener('mousedown', this.drawstart, false);
        this.canvas.addEventListener('mousemove', this.drawmove, false);
        this.canvas.addEventListener('mouseup', this.drawend, false);
    }else{
        console.warn("[mounted] canvas is undefined")
    }
    window.addEventListener("resize", () => this.setsize());
    setTimeout(() => {
        this.setsize();
        this.setCanvasImg();
        this.update_color();

        setTimeout(() => {
            this.setsize();
            this.setCanvasImg();
        }, 100);

    }, 100);
  },
  methods:{
    setCanvasImg(){
        if(this.imgRes == undefined || this.ctx == undefined || this.canvas == undefined) return;
        this.getimgsrc();
        this.imgRes.onload = () => {       
            if(this.imgRes == undefined || this.ctx == undefined || this.canvas == undefined) return;
            this.ctx.drawImage(this.imgRes, 0, 0, this.canvas.width, this.canvas.height);
        }
    },
    setsize(){
        this.getimgsrc();
        if(this.imgRes == undefined || this.board == undefined || this.canvas == undefined){
            console.log("[setsize] function call failed: ", this.imgRes == undefined, this.board == undefined, this.canvas == undefined)
            return;
        }
        this.scale = this.imgRes.width / this.imgRes.height;
        this.cw = this.board.offsetWidth;
        const h = this.cw * (1 / this.scale);
        this.canvas.height = h;
        this.canvas.width = this.cw;

        this.canvas.onload = () => {
            this.isCanvasLoad = true;
            this.triggerLoad();
        }
        this.imgRes.onload = () => {
            this.isImgResLoad = true;
            this.triggerLoad();
        }
    },
    getimgsrc() 
    {
        this.imgRes = new Image();
        this.imgRes.src = "img/draws/" + this.imgid + ".png";
    },
    triggerLoad(){
        if(this.ctx != undefined && this.imgRes != undefined && this.canvas != undefined && this.isImgResLoad && this.isCanvasLoad){
            console.log("[setsize] Call imgRes.onload drawImage")
            this.ctx.drawImage(this.imgRes, 0, 0, this.canvas.width, this.canvas.height);
            this.setCanvasImg();
        }
    },
    drawstart(event:any)
    {
        if (this.canvas == undefined) return;

        if(this.ctx == undefined) return;
        this.ctx.beginPath();
        this.ctx.moveTo(event.pageX - this.canvas.offsetLeft, event.pageY - this.canvas.offsetTop);
        this.isDrawing = false;
        this.ctx.lineWidth = this.brushWidth; // passing brushSize as line width
        this.ctx.strokeStyle = this.selectedColor; // passing selectedColor as stroke style
        this.ctx.fillStyle = this.selectedColor; // passing selectedColor as fill style
    },
    drawmove(event:any)
    {
        if (this.isDrawing || this.ctx == undefined || this.canvas == undefined) return;

        if(this.selectedTool === "brush" || this.selectedTool === "eraser") {
            // if selected tool is eraser then set strokeStyle to white 
            // to paint white color on to the existing canvas content else set the stroke color to selected color
            this.ctx.strokeStyle = this.selectedTool === "eraser" ? "#fff" : this.selectedColor;
            this.ctx.lineTo(event.pageX - this.canvas.offsetLeft, event.pageY - this.canvas.offsetTop); // creating line according to the mouse pointer
            this.ctx.stroke(); // drawing/filling line with color

        }
    },
    drawend(event:any) 
    {
        if (this.isDrawing) return;
        this.drawmove(event);
        this.isDrawing = true;
        this.setCanvasImg();
    },
    touchstart(event:TouchEvent) { this.drawstart(event.touches[0]) },
    touchmove(event:TouchEvent) { this.drawmove(event.touches[0]); event.preventDefault(); },
    touchend(event:TouchEvent) { this.drawend(event.changedTouches[0]) },
    dataURLtoBlob(dataURL:string) 
    {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) 
        {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    },
    uploadFile() 
    {
        if(this.canvas == undefined){
            console.warn("[uploadFile] function call failed:", this.canvas == undefined)
            return;
        }

        const dataURL = this.canvas.toDataURL();
        const blob = this.dataURLtoBlob(dataURL);

        const file = new File([blob], 'Draw.jpg', { type: 'image/jpg' });

        const formData = new FormData();
        formData.append('file', file);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', 'upload.php', true);

        xhr.onload = () =>
        {
            if (xhr.status === 200) 
            {
                this.debugText = '上傳成功';
                this.debugColor = "#3cff00";
            } 
            else 
            {
                this.debugText = '上傳失敗';
                this.debugColor = "#ff0000";
            }
            console.log(xhr.responseText);
        };
        xhr.send(formData);
    },


    clear_button_click(){
        this.ctx?.clearRect(0, 0, this.canvas?.width || 0, this.canvas?.height || 0); // clearing whole canvas
        this.setCanvasImg();
    },
    save_button_click(){
        if(this.canvas == undefined) return;
        const dataURL = this.canvas.toDataURL('image/jpg');
        const newWindow = window.open();
        const rawHTML = `
            <body style='width: 100%; height:100vh;'>
                <img style='position:absolute; left:50%; top:50%; transform: TranslateX(-50%) TranslateY(-60%)' src="${dataURL}" width="${this.canvas.width}" height="${this.canvas.height}">
            </body>
        `
        newWindow?.document.write(rawHTML);
        // const link = document.createElement("a"); // creating <a> element
        // link.download = `${Date.now()}.jpg`; // passing current date as link download value
        // link.href = canvas.toDataURL(); // passing canvasData as link href value
        // link.click(); // clicking link to download image
    },
    load_button_click(){
        this.uploadFile();
    },
    start_select_color(){
        this.selectColorPopup = true;
        this.previewColor = this.selectedColor;
    },
    confirm_select_color(){
        this.selectColorPopup = false;
        this.selectedColor = this.previewColor;
        this.update_color();
    },
    update_color(){
        const result = CssFilterConverter.hexToFilter(this.selectedColor).color || "";
        this.filterString = result;
    }
  },
  data() : DataFrame {
    return {
        canvas: undefined,
        ctx: undefined,
        board: undefined,

        isDrawing: false,
        selectedTool: "brush",
        brushWidth: 5,
        selectedColor: "#ff7300",
        previewColor: "",
        imgid: 0,

        imgRes: undefined,
        sections: undefined,
        nextButton: undefined,
        previousButton: undefined,

        selectColorPopup: false,

        filterString: "",
        debugText: "",
        debugColor: "red",
        scale: 0,
        cw: 0,
        width: 0,
        height: 0,
        mode: 0,

        isCanvasLoad: false,
        isImgResLoad: false
    }
  }
})