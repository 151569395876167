<template>
  <div class="background">
    <div class="titleimg">
        <div class="Icon"></div>

        <div class="home-button">
          <RouterLink to="/">
            <button class="home"></button>
          </RouterLink>
        </div>
        
        <div class="container">
          <div class="drawing-board" id="drawing-board">
            <canvas ref="canvas" style="transform: translateY(3.5px);" id="canvas">
            </canvas>
          </div>
          <section class="tools-board my-8 px-3">
            <v-row class="py-3" style="transform: translateY(-0.5vh)">
              <v-col cols="4">
                <v-btn variant="text" density="compact" icon="mdi-brush" @click="selectedTool = 'brush'">
                  <v-img :style="{'filter': selectedTool == 'brush' ? 'invert() brightness(50%) sepia(100) saturate(100) hue-rotate(-120deg)' : ''}" src="./../../public/icons/Brush.png" width="35"></v-img>
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn variant="text" density="compact" icon="mdi-eraser" @click="selectedTool = 'eraser'">
                  <v-img :style="{'filter': selectedTool == 'eraser' ? 'invert() brightness(50%) sepia(100) saturate(100) hue-rotate(-120deg)' : ''}" src="./../../public/icons/Eraser.png" width="35"></v-img>
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn variant="text" density="compact" icon="mdi-eyedropper-variant" :color="selectedColor" @click="start_select_color">
                  <div style="
                    width:35px; 
                    height:35px; 
                    background-size: cover; 
                    background-image: url('./icons/Color.png');"
                    :style="{'color':selectedColor, 'filter': filterString + ' drop-shadow(0px 0px 1.5px #222)'}" src="./../../public/icons/Color.png" width="35">
                    
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </section>
          <section class="tools-board-bottom my-8 pt-2 pb-3 px-3">
            <p for="size-slider">筆刷大小</p>
            <input v-model="brushWidth" type="range" class="slider" id="size-slider" min="1" max="30" value="5">

            <v-row class="py-4 px-3">
              <v-col cols="4">
                <v-btn density="compact" variant="text" @click="clear_button_click">
                  <v-img src="./../../public/icons/Clear Button.png" cover width="60"></v-img>
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn density="compact" variant="text" @click="save_button_click">
                  <v-img src="./../../public/icons/Save Button.png" cover width="60"></v-img>
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn density="compact" variant="text" @click="load_button_click">
                  <v-img src="./../../public/icons/upload Button.png" cover width="60"></v-img>
                </v-btn>
              </v-col>
            </v-row>

            <p>{{ debugText }}</p>
          </section>
      </div>
    </div>

    <v-dialog width="auto" transition="dialog-top-transition" v-model="selectColorPopup">
      <v-card>
        <v-card-title>
          選擇顏色
        </v-card-title>
        <v-card-text>
          <v-color-picker mode="rgb" v-model="previewColor"></v-color-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="confirm_select_color">選擇</v-btn>
          <v-btn color="error" @click="selectColorPopup = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script lang="ts" src="./Drawing.ts" />

<style scoped >
@import url(./Drawing.css);
</style>