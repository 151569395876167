<template>
  <div class="background">
    <div class="titleimg">
      <div class="title">
        <RouterLink to="Guide">
          <button class="next"></button>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'v-home',
})
</script>

<style scoped>
.title{
  background-image: url('./../../public/img/Title.png'); /* Replace 'your-background-image.jpg' with your image path */
}

button {
  background-image: url("./../../public/icons/Start Button.png");
}
</style>